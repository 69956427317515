<template>
	<div id="wrap">
		<!-- Image -->
		<div class="image">
			<img :src="src" :height="height" />
		</div>

		<!-- Artist & Title -->
		<template v-if="artwork.artistName">
			<div class="artist">{{ artwork.artistName }}</div>
			<div class="title">
				{{ artwork.title }}{{ artwork.year && artwork.year.length ? ', ' + displayYear(artwork.year) : '' }}
			</div>
		</template>

		<!-- Caption -->
		<div v-else class="caption">{{ artwork.caption }}</div>

		<!-- Error -->
		<div class="error-flag">ERROR</div>
		<div class="error">{{ artwork.error }}</div>
	</div>
</template>

<script>
import displayYear from '@/use/DisplayYear'

export default {
	name: 'ArtThumbInfoError',
	props: {
		artwork: {
			type: Object,
			required: true,
		},
		src: String,
		height: {
			type: Number,
			default: 200,
		},
		// key: Number, // No allowed, not used??
	},
	setup() {
		return { displayYear }
	},
}
</script>

<style scoped lang="scss">
// Wrap
#wrap {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	flex: 0;
	font-size: 0;
	line-height: 0;
	margin-bottom: 0.3rem;
}

// Image
#wrap .image {
	font-size: 0;
	line-height: 0;
	background: rgba(221, 0, 0, 0.5);
	margin-bottom: 0.1rem;
	overflow: hidden;
	border-radius: $br;
}
#wrap .image img {
	-webkit-filter: grayscale(100%);
	filter: grayscale(100%);
	transform: scale(1.05);
	opacity: 0.5;
	mix-blend-mode: multiply;
}

// Text
#wrap .artist {
	font-size: $regular;
	line-height: $regular-line-height;
	margin-bottom: 0.05rem;
}
#wrap .title,
#wrap .caption {
	font-size: $small;
	line-height: $small-line-height;

	// Truncate title to max two lines
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}
#wrap .caption {
	// Truncate captions to 4 lines
	-webkit-line-clamp: 4;
}
#wrap .title:not(:hover) {
	color: $black-50;
}

// Error
#wrap .error-flag {
	color: #fff;
	background: $bad;
	font-size: $small;
	height: 0.2rem;
	line-height: 0.2rem;
	border-radius: $br;
	padding: 0 0.05rem;
	margin: 0.05rem 0;
}
#wrap .error {
	color: $bad;
	font-size: $small;
	line-height: 0.18rem;
}
</style>
