import { defineStore } from 'pinia'

export const useUploadStore = defineStore('UploadStore', {
	state: () => ({
		// Defaults are set in Upload.vue --> created()
		defaults: {
			isArtwork: null,
			showAdvancedFields: null,
		},
		sharedPropToggle: false,
		sharedPropData: {
			artistId: null,
			artistName: null, // Used to propagate to individual artworks artist input.
			artistIsSelf: null,
			errors: {},
		},
		// Use ?debug=1/2/3 to inspect uploadData
		uploadData: [],
		myUserId: null,
	}),
	getters: {
		fileCount() {
			return this.uploadData.length
		},
		uploadErrors() {
			return this.uploadData.map(itm => itm.errors)
		},
		imgLoading() {
			return this.uploadData.map(itm => itm.imgLoading)
		},
		tempFileNames() {
			return this.uploadData.map(itm => itm.tempFileName)
		},
		formattedData() {
			return _formattedData.bind(this)()
		},
		// This enables the submit button after all images have been uploaded (from device only).
		readyForSubmit() {
			// Images imported from the web don't have upload progress.
			const hasProgressBar = this.uploadData.some(itm => itm.progress)
			if (!hasProgressBar) return true
			// Ready when all images are 100% uploaded.
			const isReady = !this.uploadData.some(itm => !itm.progress || itm.progress < 100)
			// console.log(this.uploadData.map(itm => itm.progress).join('/'), 'isReady: ', isReady)
			return isReady
		},
	},
	actions: {
		/**
		 * General
		 */

		// Because we can't Vue inside the store
		// we need to load myUserId on load
		storeMyUserId(id) {
			this.myUserId = id
		},

		// Clear all store data (when exiting form).
		clearStore() {
			this.sharedPropToggle = false
			this.sharedPropData = {}
			this.uploadData = []
		},

		/**
		 * Defaults
		 */

		// Initialize default values on page load.
		// We can't read cookies server side, so we read them on created().
		initDefaults(defaults) {
			Object.entries(defaults).forEach(([key, val]) => {
				this.setDefault(key, val)
			})
			// Set default values
			this.applyDefaultsToItms(this.uploadData)
		},

		// Apply defaults to itms in uploadData.
		// This is called on created() and every time new artworks are added.
		applyDefaultsToItms(itmArray) {
			itmArray = itmArray.map(itm => {
				itm.isArtwork = this.defaults.isArtwork
				itm.showAdvancedFields = this.defaults.showAdvancedFields
				return itm
			})
			return itmArray
		},

		// Set shared property value & propagate to all upload items.
		// Triggered whenever you change a defaults toggle (isArtwork / showAdvancedFields)
		setDefault(key, val) {
			this.defaults[key] = val
		},

		// When all itms are set to value X, the default value updates accordingly.
		syncDefault(key, val) {
			const sameValueItmCount = this.uploadData.filter(itm => itm[key] == val).length
			if (sameValueItmCount == this.uploadData.length) {
				this.setDefault(key, val)
			}
		},

		/**
		 * Shared Props
		 */

		// Turn shared props on or off.
		toggleSharedProps() {
			const toggleState = !this.sharedPropToggle
			this.sharedPropToggle = toggleState
		},

		// Set shared property value & propagate to all upload items.
		setSharedProp(key, val) {
			this.sharedPropData[key] = val
			this.uploadData.forEach((item, i) => {
				const data = {}
				data[key] = val
				this.updateItem(i, data)
			})
		},

		// Set shared prop error.
		setSharedPropError(key, val) {
			if (!this.sharedPropData.errors) this.sharedPropData.errors = {}
			this.sharedPropData.errors[key] = val
		},

		/**
		 * Upload Data
		 */

		// Add single item to data array.
		// Used for seeding. Currently not used
		// addItem({ commit }, item) {
		// 	commit('ADD_ITEM', item)
		// },

		// Add multiple itms to the uploadData.
		// All itms in uploadData pass through here.
		addMultipleItems(itmArray) {
			this.applyDefaultsToItms(itmArray)
			this.uploadData = this.uploadData.concat(itmArray)
		},

		// Add data for single item (multiple keys at once).
		updateItem(i, data) {
			Object.entries(data).forEach(([key, val]) => {
				// Need the condition in case update comes in after
				// store has been cleared (on cancel), eg. progress.
				if (this.uploadData[i]) {
					if (val) {
						this.uploadData[i][key] = val
					} else {
						delete this.uploadData[i][key]
					}
				}
			})
		},

		// Add error for single item (multiple keys at once).
		updateItemError(i, data) {
			Object.entries(data).forEach(([key, val]) => {
				if (!this.uploadData[i]) return // Required when you remove image.
				// Delete error if value is empty.
				if (!val) {
					if (this.uploadData[i].errors && this.uploadData[i].errors[key]) {
						return delete this.uploadData[i].errors[key]
					}
					return
				}

				// Add error.
				if (this.uploadData[i].errors) {
					this.uploadData[i].errors[key] = val
				} else {
					const errors = {}
					errors[key] = val
					this.uploadData[i].errors = errors
				}
			})
		},

		// Add data for array of items.
		// Used to set tempFilenames.
		updateMultipleItems({ startIndex, dataArray }) {
			startIndex = startIndex || 0
			dataArray.forEach((data, i) => {
				this.updateItem(startIndex + i, data)
			})
		},

		removeItem(i) {
			this.uploadData.splice(i, 1)
		},

		// Validate.
		validate() {
			let totalErrorCount = 0

			// Validate shared props.
			const { artistId, artistIsSelf, errors: sharedPropErrors } = this.sharedPropData
			if (this.sharedPropToggle && artistId) {
				// Artist --> "I am the artist" dropdown is required.
				if (!artistIsSelf && artistIsSelf != Boolean(artistIsSelf)) {
					this.setSharedPropError(['artistIsSelf', 'Required'])
				}
			}

			// Count shared prop errors.
			totalErrorCount += Object.entries(sharedPropErrors || {}).filter(entry => !!entry[1]).length

			// Validate upload items.
			// Keep in sync with ArtworkEdit.vue
			this.uploadData.forEach((item, i) => {
				// Ignore items with image errors because they're disabled.
				if (item.errors && item.errors.image) return totalErrorCount++

				// Require artistId per item.
				if (item.isArtwork && !item.artistId) {
					this.updateItemError(i, { artist: 'required' })
				}

				// Return error Boolean.
				const errorCount = Object.keys(item.errors || {}).length
				totalErrorCount += errorCount
			})
			return totalErrorCount
		},
	},
})

//

// export const mutations = {
// 	SET_ARTIST_IS_SELF(val) {
// 		this.sharedPropData.artistIsSelf = val
// 	},
// }

// Data formatted for API.
// - - -
// A number of data items have a lot of superfluous information that
// we need to validate the fields, but once validated they can be folded
// into a single value (eg. artist or year).
function _formattedData() {
	// Get uploadData
	let { uploadData, sharedPropToggle, sharedPropData } = this

	// Abort in case of errors.
	// This function isn't called unless validation succeeded,
	// but just in case we check again and abort if needed.
	let hasErrors = false
	uploadData.forEach(item => {
		if (item.errors && Object.keys(item.errors).length) {
			hasErrors = true
		}
	})
	if (hasErrors) return {}

	/*
	Final data structure
	- - -
	{
		artistSelf: {
			artistId: '3oj9J67PP',
			userId: 'vDPCiwvyrOBB'
		},
		artworks: [
			{
				// Base data	
				listIds: ['uePrPYsmgge'],
				roomIds: ['sXlyNq7Kn-c'],
				labels: ['qpZyk-dfv', '11FBTw9ym'],
				caption: 'This is the artist\'s most famou readymade',
				contributor: 'vDPCiwvyrOBB',
				collectors: ['vDPCiwvyrOBB']
				views: [{
					originalFileName: 'marcel-duchamp--fountain-1917.jpg',
					tempFileName: 'tmp-1642974712180.GUIwt8JjVG99.marcel-duchamp--fountain-1917.jpg',
					width: 730,
					height: 450,
					origin: {
						img: 'https://aws-cloud.com/xyz-blabla.jpg',
						dom: 'https://example.com',
						pag: 'https://example.com/collection/abc',
						fn: 'xyz-blabla.jpg'
					}
				}]
				
				// Artwork data
				artistId: '3oj9J67PP',
				title: 'Fountain',
				year: [1910, 1920],
				medium: 'multimedia',
				materialsFF: 'Two-channel realtime 3D simulation',
				dim: { w: 3000, h: 4000, d: 2500 }, // In mm
		
				// Additional
				photoCredit: 'Billy Brown'
			}
		]
	}
	*/

	// Map uploadData into data structure for API
	uploadData = uploadData.map(item => {
		// Merge labels & materialLabels.
		const labels = item.labels || []
		const materialLabels = item.materialLabels || []
		let allLabels = labels.concat(materialLabels)
		allLabels = allLabels.length ? allLabels : null

		// Base data - shared between artworks and images.
		let data = {
			isArtwork: item.isArtwork,
			listIds: item.listIds,
			roomIds: item.roomIds,
			labels: allLabels,
			caption: item.caption,
			contributor: this.myUserId,
			collectors: [this.myUserId],
			views: [
				{
					originalFileName: item.originalFileName,
					tempFileName: item.tempFileName,
					width: item.width,
					height: item.height,
					origin: item.origin,
				},
			],
		}

		// Data exclusive for artworks
		return item.isArtwork
			? {
					...data,
					artistId: item.artistId, // <-- reduced to id
					title: item.title,
					year: item.year,
					medium: item.medium,
					materialsFF: item.materialsFF,
					dim: item.dim,
					photoCredit: item.photoCredit,
			  }
			: data
	})

	// If the uploader is the artist, we connect artist and user accounts.
	if (sharedPropToggle && sharedPropData.artistId && sharedPropData.artistIsSelf) {
		// We wrap the artworks array (uploadData) in a parent object with
		// an artistId-userId sibling (artistSelf). This gets stripped first
		// thing in upload.js in the API.
		uploadData = {
			artistSelf: {
				artistId: uploadData[0].artistId,
				userId: this.myUserId,
			},
			artworks: uploadData,
		}
	}

	// Return formatted data array.
	return uploadData
}
