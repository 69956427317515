<template>
	<input id="file-btn" type="file" @change="e => $emit('change', e)" multiple accept="image/*" />
	<label id="form-file" for="file-btn">
		Select Files
	</label>
</template>

<script>
export default {
	name: 'FormFile',
	emits: ['change'],
}
</script>

<style lang="scss" scoped>
#form-file {
	background: #fee;
	background-color: $black-05;
	height: 0.4rem;
	border-radius: $br;
	display: inline-block;
	text-align: center;
	line-height: 0.4rem;
	padding: 0 0.16rem;
	position: relative;
	cursor: pointer;
}
#file-btn {
	width: 0;
	height: 0;
	opacity: 0; // Can't do display none bc of focus
}

@media (hover: hover) {
	#file-btn:focus + label::after {
		content: '';
		position: absolute;
		top: 0.04rem;
		bottom: 0.04rem;
		left: 0.04rem;
		right: 0.04rem;
		border: solid 0.01rem $black-10;
	}
	#form-file:hover {
		background-color: $black-15;
	}
}
</style>
