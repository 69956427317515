<template>
	<h1>Upload Complete</h1>
	<p v-html="successMessage"></p>
	<FormButton v-if="totalArtworkCount > 10" :level="1" value="Done" @click="exitSuccess" class="bump-t" />
	<div id="artwork-preview-wrap">
		<ArtThumbInfoError
			v-for="(artwork, i) in failedArtworks"
			:src="localSrcs[artwork.index]"
			:key="i"
			:artwork="artwork"
			imgSize="small"
		/>
		<ArtThumb
			v-for="(itm, i) in successArtworks"
			:key="i"
			:itm="itm"
			:showInfo="true"
			imgSize="small"
			:disableCarousel="true"
		/>
	</div>
	<br /><br />
	<FormButton :level="1" value="Done" @click="exitSuccess" />
</template>

<script>
import FormButton from '@/components/FormButton'
import ArtThumb from '@/components/ArtThumb'
import ArtThumbInfoError from '@/components/ArtThumbInfoError'

export default {
	name: 'UploadSuccess',
	components: {
		FormButton,
		ArtThumb,
		ArtThumbInfoError,
	},
	props: {
		localSrcs: Array,
		successArtworks: Array,
		failedArtworks: Array,
	},
	emits: ['exit'],
	computed: {
		totalArtworkCount() {
			return this.successArtworks.length + this.failedArtworks.length
		},
		successMessage() {
			if (!this.successArtworks.length) {
				// All failed
				if (this.failedArtworks.length > 1) {
					return `<span class="error-msg">All ${this.failedArtworks.length} of your images failed to upload.</span>`
				} else {
					return '<span class="error-msg">Your image upload failed.</span>'
				}
			} else {
				const s_success = this.successArtworks.length > 1 ? 's' : ''
				// const s_fail = this.failedArtworks.length > 1 ? 's' : ''
				const successCount = this.successArtworks.length == 1 ? 'one' : this.successArtworks.length
				const failCount = this.failedArtworks.length == 1 ? 'one' : this.failedArtworks.length
				const messageBase = `You've successfully uploaded ${successCount} image${s_success}`
				if (!this.failedArtworks.length) {
					// All succeeded
					return `${messageBase}.`
				} else {
					// Some failed
					return `<span class="error-msg">It seems like ${failCount} of your images failed to upload.</span>`
				}
			}
		},
	},
	methods: {
		exitSuccess() {
			this.$emit('exit')
		},
	},
}
</script>

<style scoped lang="scss">
// Success
#artwork-preview-wrap {
	display: flex;
	flex-wrap: wrap;
	gap: 0.4rem;
	margin-top: 0.3rem;
}
</style>
