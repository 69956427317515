<template>
	<div v-if="!files.length && !urlFileNames.length" class="content-pad">
		<!-- Success screen -->
		<UploadSuccess
			v-if="successData"
			:localSrcs="successData.localSrcs"
			:successArtworks="successData.successArtworks"
			:failedArtworks="successData.failedArtworks"
			@exit="exitSuccess"
		/>

		<!-- Landing -->
		<template v-else>
			<!-- Drop target -->
			<div
				id="drop-zone"
				:class="{ hover }"
				@drop.prevent="addFiles"
				@dragenter.prevent="hover = true"
				@dragover.prevent
				@dragleave.prevent="hover = false"
			>
				<div id="drop-target-text">Drag &amp; drop<br />your files</div>
				<div id="drop-target">
					<!-- prettier-ignore -->
					<svg width="3rem" height="3rem" viewBox="0 0 300 300" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
						<circle cx="150" cy="150" r="148" fill="none" stroke-width="2" stroke-dasharray="8"></circle>
					</svg>
				</div>
			</div>

			<!-- Instructions -->
			<div v-if="!successData" id="instructions" @dragenter.prevent="hover = true">
				<h1>Upload Artwork</h1>
				<div class="block extension">
					<h3>From the internet</h3>
					<p class="bump-b-20">Install our browser extension.</p>
					<ButtonExtension />
				</div>
				<div class="block">
					<h3>From your computer</h3>
					<p class="bump-b-20">
						Select images or drag &amp; drop them onto this page.
					</p>
					<FormFile @change="addFiles" />
				</div>
				<!-- <div class="block">
				<h3>Advanced uploading</h3>
				<p class="bump-b-20">
					Upload large batches using a spreadsheet.
				</p>
				<FormButton :level="2" value="Get Started" />
			</div> -->
			</div>
		</template>
	</div>

	<!-- Uploading -->
	<UploadForm
		v-else
		:files="files"
		:urlFileNames="urlFileNames"
		:origins="origins"
		@reset="reset"
		@success="onSuccess"
		:debug="$route.query.debug ? +$route.query.debug : null"
		:dummy="$route.query.dummy ? +$route.query.dummy : null"
	/>
</template>

<script>
/*

DEBUGGING
- - -

Data display:
?debug=1 --> Total data
?debug=2 --> Form item data
?debug=3 --> Both

Dummy data:
?dummy=1 --> Form data
?dummy=2 --> Loading screen
?dummy=3 --> Success data

*/

// Vue
import { inject } from 'vue'

// Stores
import { useUploadStore } from '@/stores/UploadStore'

// Modules
import Cookies from 'universal-cookie'

// Page segments
import UploadForm from '@/views/Upload/UploadForm'
import UploadSuccess from '@/views/Upload/UploadSuccess'

// Components
// import FormButton from '@/components/FormButton'
import FormFile from '@/components/FormFile'
import ButtonExtension from '@/components/ButtonExtension'

// Internal
import { debugFiles } from '@/use/UploadDummyData'
import { debugSuccessData } from '@/use/UploadDummyData'
import { injectMetaData } from '@/use/MetaData'

export default {
	name: 'UploadMain',
	components: {
		UploadForm,
		UploadSuccess,
		// FormButton,
		FormFile,
		ButtonExtension,
	},
	props: {
		// Image urls are added to URL like ?img=tempfilename1.jpg&img=tempfilename2.jpg
		// These URLs are processed in the router, removed and sent as a prop.
		// They're loaded into a data object because on success they are deleted.
		initialUrlFileNames: Array,
		// Object with origin data which is stored in the database and as meta into on B2.
		origins: Object,
	},
	setup() {
		const uploadStore = useUploadStore()

		// Store my id
		const $myUserId = inject('$myUserId')
		uploadStore.storeMyUserId($myUserId)

		// Meta data
		injectMetaData({ title: 'Arthur.io • Upload' })

		return { uploadStore }
	},
	data() {
		return {
			// fileList from upload - array of {src, name}
			files: this.$route.query.dummy == 1 || this.$route.query.dummy == 2 ? debugFiles : [],

			// See prop
			urlFileNames: this.initialUrlFileNames || [],

			// Success data - Two separate arrays of artwork objects:
			// { successArtworks, failedArtworks }
			successData: this.$route.query.dummy == 3 ? debugSuccessData : null,

			hover: false,
		}
	},
	mounted() {
		// Load defaults from cookies
		const cookies = new Cookies()
		this.uploadStore.initDefaults({
			isArtwork: cookies.get('isArtwork') !== 'false',
			showAdvancedFields: cookies.get('showAdvancedFields') === 'true',
		})
	},
	methods: {
		// Load files from select input or drop event.
		addFiles(e) {
			this.files = e.target.files || e.dataTransfer.files
			e.preventDefault()
		},
		onSuccess(data) {
			this.successData = data
		},
		reset() {
			this.urlFileNames = []
			this.files = []
			this.loading = false
			this.hover = false
		},
		exitSuccess() {
			this.successData = null
		},
	},
}
</script>

<style scoped lang="scss">
/**
 * Landing page
 */
.block {
	margin-bottom: 0.5rem;
}
h3 {
	margin-bottom: 0;
}

// Instructions
#instructions {
	display: inline-block;
	position: relative;
}

// Drop zone
#drop-zone {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

// Drop target
#drop-target {
	width: 3rem;
	height: 3rem;
	border-radius: 2rem;
	background: $white-50;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	z-index: -1;
	pointer-events: none;
	// transition-property: width, height;
	// transition-duration: 100ms;
	// transition-timing-function: ease-in-out;
}
#drop-target-text {
	position: absolute;
	left: 50%;
	top: 50%;
	width: 2rem;
	height: 2rem;
	margin: -1rem 0 0 -1rem;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	pointer-events: none;
}
#drop-target svg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0.2;
	stroke: #000;
}

// Hover effect
#drop-zone.hover {
	background: $black-opaque;
	z-index: 100;
}
#drop-zone.hover #drop-target {
	// width:3.5rem;
	// height:3.5rem;
	background: #fff;
	background: transparent;
}
#drop-zone.hover #drop-target svg {
	stroke: #fff;
	animation: rotate 15s infinite linear;
	opacity: 0.4;
}
#drop-zone.hover #drop-target-text {
	color: #fff;
}
#drop-zone.hover ~ * {
	display: none;
}

@media (hover: none) {
	#drop-zone,
	.block.extension,
	h1,
	h3,
	p {
		display: none;
	}
	.content-pad {
		height: 100%;
		width: 100%;
	}
	#instructions {
		width: 100%;
		height: 100%;
	}
	#instructions:deep() #form-file {
		position: absolute;
		width: 100%;
		height: 100%;
		padding: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	#instructions:deep() #form-file::before {
		content: 'Take Photo /\00a0';
		display: block;
		position: relative;
	}
	#instructions:deep() #file-btn {
		position: absolute;
	}
}
</style>
