<template>
	<DebugDisplay v-if="debug && (debug == 1 || debug == 3)" :data="itemUploadData" style="margin-top:0.2rem" />
	<!-- Form -->
	<div id="upload-item" :class="{ disabled: !!imgError, form: true }">
		<!-- Image column -->
		<div class="img-column">
			<!-- For debugging the progress bar -->
			<!-- <input type="text" v-model="itemUploadData.progress" /> -->

			<!-- Image loading -->
			<div v-if="itemImgLoading" id="img-placeholder-loading"></div>

			<!-- Image -->
			<img
				v-else-if="!imgError"
				id="img-preview"
				:class="{ processing: itemUploadData.progress == 100 }"
				:src="imgSrc"
			/>

			<!-- Image Error -->
			<div v-else id="img-placeholder-error">
				<BaseIcon name="invalid" color="#d00" />
				<span class="error-msg">{{ imgError }}</span>
				<!-- <FormButton small error value="Remove" @click="removeItem(index)" /> -->
			</div>

			<div id="processing-flag" v-if="itemUploadData.progress == 100">
				<div>Processing...</div>
			</div>

			<!-- Upload progress -->
			<transition name="progress-trans">
				<div
					v-if="itemUploadData.progress != 102"
					id="progress-bar"
					:class="{
						loading: itemUploadData.progress < 101,
						processing: itemUploadData.progress == 100,
						'processing-done': itemUploadData.progress == 101,
					}"
				>
					<div :style="{ width: Math.min(itemUploadData.progress, 100) + '%' }"></div>
				</div>
			</transition>

			<!-- Filename -->
			<div id="filename">
				<span>{{ itemUploadData.originalFileName }}</span>
				<!-- <a
					v-if="imgSrc"
					:href="`https://images.google.com/searchbyimage?image_url=${imgSrc}&q=test`"
					target="_blank"
					>Image lookup</a
				> -->
				<a href="#" @click.prevent="removeItem(index)">Remove image</a>
			</div>
		</div>

		<!-- Form -->
		<div class="form-column">
			<div class="structured-form">
				<!-- Image type & List/Room dropdown -->
				<div class="option-bar" @click="onClickOptionBar" :class="{ disabled: !!imgError }">
					<FormToggle
						v-model="isArtwork"
						label="Art / Design"
						class="toggle"
						:disabled="!!imgError"
						@update="onToggle"
					/>
					<ActionCluster v-model="cluster" :disabled="!!imgError" :debug="debug && debug > 1" />
				</div>

				<!-- Artist & Title -->
				<template v-if="itemUploadData.isArtwork">
					<div
						v-if="itemUploadErrors.artist == 'Required'"
						id="required-artist"
						class="error-msg full-width small"
					>
						If this image does not have a relevant author, turn off the art/design toggle.
					</div>
					<FormText
						v-if="sharedPropToggle && sharedPropData.artistId"
						label="Artist *"
						:locked="true"
						:readOnlyValue="sharedPropData.artistName"
						:error="itemUploadErrors.artist"
					/>
					<FormArtist
						v-else
						ref="artist"
						:disabled="!!imgError"
						:error="itemUploadErrors.artist"
						@update="onUpdateArtist"
						:debug="debug && debug > 1"
					/>
					<FormText
						label="Title"
						v-model="title"
						maxlength="200"
						:disabled="!!imgError"
						@paste="onPasteTitle"
					/>

					<!-- Year -->
					<FormYear
						v-model="year"
						:disabled="!!imgError"
						@error="err => updateStoreError('year', err)"
						:debug="debug && debug > 1"
					/>
				</template>

				<!-- Labels -->
				<FormLabels
					ref="labels"
					:class="{ 'full-width': !itemUploadData.isArtwork }"
					:disabled="!!imgError"
					@update="val => updateStore('labels', val)"
					:debug="debug && debug > 1"
				/>

				<!-- Caption -->
				<FormText
					v-model="caption"
					label="Caption"
					class="full-width"
					type="textarea"
					maxlength="10000"
					:disabled="!!imgError"
				/>

				<!-- Dimensions & Medium -->
				<template v-if="showAdvancedFields && itemUploadData.isArtwork">
					<FormDimensions v-model:dim="dim" :disabled="!!imgError" :debug="debug && debug > 1" />
					<FormLabels
						:isMedium="true"
						:disabled="!!imgError"
						@update="updateStoreMultiKey"
						:debug="debug && debug > 1"
					/>
				</template>

				<!-- Photo credit -->
				<FormPhotoCredit v-if="showAdvancedFields" v-model="photoCredit" />

				<!-- Show advaned link -->
				<a
					v-if="!showAdvancedFields && itemUploadData.isArtwork"
					href="#"
					@click.prevent="showAdvancedFields = !showAdvancedFields"
					id="advanced-fields"
					>Advanced fields</a
				>
			</div>
		</div>
	</div>
</template>

<script>
// Vue
import { nextTick } from 'vue'
import { mapState } from 'pinia'

// Stores
import { useUploadStore } from '@/stores/UploadStore'

// Components
// import FormButton from '@/components/FormButton'
import FormArtist from '@/components/FormArtist'
import FormText from '@/components/FormText'
import FormYear from '@/components/FormYear'
// import FormDropdown from '@/components/FormDropdown'
import FormToggle from '@/components/FormToggle'
import FormLabels from '@/components/FormLabels'
import FormDimensions from '@/components/FormDimensions'
import FormPhotoCredit from '@/components/FormPhotoCredit'
import ActionCluster from '@/components/ActionCluster'
import BaseIcon from '@/components/BaseIcon'
import DebugDisplay from '@/components/DebugDisplay'

export default {
	name: 'UploadItem',
	components: {
		// FormButton,
		FormArtist,
		FormText,
		FormYear,
		// FormDropdown,
		FormToggle,
		FormLabels,
		FormDimensions,
		FormPhotoCredit,
		ActionCluster,
		BaseIcon,
		DebugDisplay,
	},
	props: {
		// Index in upload data array
		index: {
			type: Number,
			default: 0,
		},

		// 1 = Total data
		// 2 = Form item data
		// 3 = Both
		debug: {
			type: [Number, null],
			default: null,
		},
	},
	emits: ['change'],
	computed: {
		// Pinia state
		...mapState(useUploadStore, [
			'defaults',
			'sharedPropToggle',
			'sharedPropData',
			// Actions
			'syncDefault',
			'updateItem',
			'updateItemError',
			'removeItem',
			// Getters
			'uploadData',
			'uploadErrors',
			'imgLoading',
		]),

		// Upload data
		itemUploadData() {
			return this.uploadData[this.index]
		},

		// v-model isArtwork
		isArtwork: {
			get() {
				return this.itemUploadData ? this.itemUploadData.isArtwork : null
			},
			set(val) {
				this.updateStore('isArtwork', val)
			},
		},

		// v-model cluster
		cluster: {
			get() {
				return this.itemUploadData && this.itemUploadData.listIds
					? this.itemUploadData.listIds[0]
					: this.itemUploadData && this.itemUploadData.roomIds
					? this.itemUploadData.roomIds[0]
					: null
			},
			set(val) {
				const { id, type } = val
				if (type == 'list') {
					this.updateStore('listIds', [id])
					this.updateStore('roomIds', null)
				} else if (type == 'room') {
					this.updateStore('listIds', null)
					this.updateStore('roomIds', [id])
				}
			},
		},

		// v-model title
		// We use v-model fot title to split off the year on paste.
		title: {
			get() {
				return this.itemUploadData ? this.itemUploadData.title : null
			},
			set(val) {
				this.updateStore('title', val)
			},
		},

		// v-model year
		year: {
			get() {
				return this.itemUploadData.year
			},
			set(val) {
				this.updateStore('year', val)
			},
		},

		// v-model dimensions
		dim: {
			get() {
				return this.itemUploadData ? this.itemUploadData.dim : null
			},
			set(val) {
				this.updateStore('dim', val)
			},
		},

		// v-model caption
		caption: {
			get() {
				return this.itemUploadData.caption
			},
			set(val) {
				this.updateStore('caption', val)
			},
		},

		// v-model caption
		photoCredit: {
			get() {
				return this.itemUploadData.photoCredit
			},
			set(val) {
				this.updateStore('photoCredit', val)
			},
		},

		showAdvancedFields: {
			get() {
				return this.itemUploadData ? this.itemUploadData.showAdvancedFields : null
			},
			set(val) {
				this.updateStore('showAdvancedFields', val)
			},
		},

		// Errors
		itemUploadErrors() {
			return this.uploadErrors[this.index] || {}
		},

		// Image error (eg. corrupt file)
		imgError() {
			return this.itemUploadData.errors ? this.itemUploadData.errors.image : null
		},

		// Image loading
		// This value is updated in Form.vue.
		itemImgLoading() {
			return this.imgLoading[this.index]
		},

		// Images imported from URL are loaded from the temp folder and
		// need to be limited in size using ?small=1 but images uploaded
		// from a user's computer are previewed using a blob url.
		imgSrc() {
			const { src } = this.itemUploadData
			if (!src) return ''
			const suffix = src.match(/^blob/) ? '' : '?small=1'
			return this.itemUploadData.src + suffix
		},
	},
	watch: {
		// Propagate default value changes.
		'defaults.isArtwork'(newValue) {
			this.isArtwork = newValue
		},
		'defaults.showAdvancedFields'(newValue) {
			// If any advanced field has been set, ignore default.
			if (this.hasAdvancedData()) return
			this.showAdvancedFields = newValue
		},

		async isArtwork(newValue) {
			// Remove error message.
			if (!newValue) {
				this.updateStoreError('artist', undefined)
				this.updateStoreError('year', undefined)
			}

			// If all itm values are toggled, we sync the default.
			this.syncDefault('isArtwork', newValue)
		},
		showAdvancedFields(newValue) {
			// If all itm values are toggled, we sync the default.
			this.syncDefault('showAdvancedFields', newValue)
		},
	},
	methods: {
		// Add data to Vuex store.
		updateStore(key, value) {
			// console.log('updateStore', key, value)
			const data = {}
			data[key] = value
			this.updateItem(this.index, data)
			this.$emit('change') // Lets us hide the global error message until you resubmit.
		},

		// Add error to Vuex store.
		updateStoreError(key, value) {
			const data = {}
			data[key] = value
			this.updateItemError(this.index, data)
		},

		// Add multiple key value pairs to Vuex store at once.
		updateStoreMultiKey(data) {
			Object.keys(data).forEach(key => {
				this.updateStore(key, data[key])
			})
		},

		//
		//

		// The entire option bar activated the toggle.
		onClickOptionBar(e) {
			if (e.target.classList.contains('option-bar')) {
				this.isArtwork = !this.isArtwork
			}
		},

		// Focus on first field when clicking toggle.
		// Note: we can't have this under the isArtwork watcher because
		// we don't want it to be triggered when master toggle is clicked.
		async onToggle(isArtwork) {
			if (isArtwork) {
				await nextTick()
				await nextTick() // Yes we need two
				this.$refs.artist.focus()
			} else {
				await nextTick()
				this.$refs.labels.focus()
			}
		},

		// Store artist
		onUpdateArtist(artist) {
			const { id, inputValue, error } = artist
			this.updateStore('artistId', id || undefined)
			// When value is empty, we don't want to remove required error on blur.
			if (inputValue || this.itemUploadErrors.artist != 'Required') {
				this.updateStoreError('artist', error || undefined)
			}
		},

		// When pasting a title with year, we separate it and update the year input.
		async onPasteTitle(e) {
			const pasted = e.clipboardData.getData('Text')
			const yearMatch = pasted.match(/^(.+),\s*(\d{4})$/)
			if (yearMatch) {
				setTimeout(() => {
					const title = yearMatch[1]
					const year = yearMatch[2]
					this.title = title
					this.year = [year]
				}, 0)
			}
		},

		// prettier-ignore
		// If user has filled in any advanced data, don't allow the fields to be hidden.
		hasAdvancedData() {
			if (!this.itemUploadData || !this.itemUploadData.isArtwork) return false
			const { dim, medium, materialsFF, materialLabels } = this.itemUploadData
			let hasData = [medium, materialsFF, materialLabels].some(data => !!data && data.length)
			if (!hasData && dim) hasData = !!Object.keys(dim).length
			return hasData
		}
	},
}
</script>

<style scoped lang="scss">
// Form container
#upload-item {
	display: flex;
	padding: 0.4rem 0;
	border-top: dashed 0.01rem $black-15;
}

/**
 * Image column
 */

#upload-item .img-column {
	flex: 2.5rem 0 0;
	margin-right: 0.4rem;
	display: flex;
	flex-direction: column;
}

// Image placeholder - itemImgLoading state
#img-placeholder-loading {
	background: $black-05;
	width: 2.5rem;
	height: 1.88rem;
	margin-bottom: 0.1rem;
	position: relative;
}
#img-placeholder-loading::after {
	content: 'Loading...';
	display: block;
	color: $black-30;
	text-align: center;
	font-size: $small;
	line-height: 0.2rem;
	width: 1rem;
	height: 0.2rem;
	position: absolute;
	left: 50%;
	top: 50%;
	margin: -0.1rem 0 0 -0.5rem;
	animation: blink 1000ms infinite;
}

// Image placeholder - error state
#img-placeholder-error {
	background: $bad-soft;
	width: 2.5rem;
	height: 1.88rem;
	padding: 0.3rem;
	display: flex;
	text-align: center;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 0.1rem;
	margin-bottom: 0.1rem;
	font-size: $regular;
	line-height: $regular-line-height;
}

// Image
#img-preview {
	max-width: 100%;
	position: relative;
}

// Filename
#filename {
	margin-top: 0.1rem;
	font-size: $small;
	line-height: $small-line-height;
}
#filename span {
	word-break: break-all;
}
#filename a {
	margin-top: 0.1rem;
	display: block;
}

/**
 * Progress bar
 */

// Base
#progress-bar {
	width: 100%;
	height: 0.04rem;
	margin-bottom: -0.04rem;
	background: $black-05;
	position: relative;
	top: 0.03rem;
	border-radius: $br;
	overflow: hidden;
}

// Progress status
#progress-bar > div {
	width: 0;
	height: 100%;
	border-radius: $br 0 0 $br;
	background-color: $primary;
	position: absolute;
	top: 0;
	left: 0;
	transition-property: background-color, width;
	transition-duration: 200ms;
	transition-timing-function: linear;
}

// Progress status - 100: processing...
#progress-bar.processing > div::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	// #FFF stripes
	background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjMwcHgiIGhlaWdodD0iMzBweCIgdmlld0JveD0iMCAwIDMwIDMwIj48cmVjdCBmaWxsPSIjZmZmIiB4PSIyLjIiIHk9Ii0xNC45IiB0cmFuc2Zvcm09Im1hdHJpeCgwLjcwNzEgMC43MDcxIC0wLjcwNzEgMC43MDcxIDcuNSAtMy4xMDY2KSIgd2lkdGg9IjEwLjYiIGhlaWdodD0iNDQuNyIvPjxyZWN0IGZpbGw9IiNmZmYiIHg9IjE3LjIiIHk9IjAuMSIgdHJhbnNmb3JtPSJtYXRyaXgoMC43MDcxIDAuNzA3MSAtMC43MDcxIDAuNzA3MSAyMi41IC05LjMxOTgpIiB3aWR0aD0iMTAuNiIgaGVpZ2h0PSI0NC43Ii8+PC9zdmc+)
		0 0 repeat;
	animation: btn-wait 1s linear infinite;
	opacity: 0.5;
}

// Processing flag on top of image
#processing-flag {
	height: 0;
}
#processing-flag div {
	float: left;
	font-size: 0.12rem;
	text-transform: uppercase;
	letter-spacing: 0.01rem;
	height: 0.2rem;
	line-height: 0.2rem;
	margin-bottom: -0.2rem;
	padding: 0 0.07rem;
	background: $black-20;
	color: #fff;
	border-radius: $br;
	position: relative;
	left: 0.05rem;
	top: -0.25rem;
}

// Progress status - 101: hide 'processing...'
#progress-bar.processing > div,
#progress-bar.processing-done > div {
	background: $primary-dark;
}

// Transition
.progress-trans-enter-active,
.progress-trans-leave-active {
	transition-property: opacity;
	transition-duration: 100ms;
	transition-timing-function: ease-in-out;
}
.progress-trans-enter-from,
.progress-trans-leave-to {
	opacity: 0.3 !important;
}

/**
 * Form
 */

#upload-item .form-column {
	flex: 5.4rem 0 1;
	// Required to truncate locked artist field.
	min-width: 0;
}

// Options bar (is-artwork / cluster dropdown)
#upload-item .option-bar {
	width: 100%;
	padding: 0.1rem;
	background: $black-05;
	border-radius: $br;
	margin-bottom: 0.3rem;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
#upload-item .option-bar > .wrap {
	// flex: 1 12.4rem;
	flex: 0 02.4rem;
}
#upload-item .option-bar > .wrap.toggle {
	// flex: 1 22.3rem;
	// flex: 0 1.23rem;
	padding: 0.1rem;
}
#upload-item .option-bar.disabled {
	cursor: default;
	pointer-events: none;
}

// Required artist error message
#required-artist {
	background: $bad-soft;
	padding: 0.1rem;
	margin-top: -0.25rem;
	margin-bottom: 0.3rem;
	border-radius: $br;
}

// Advanced fields link
#advanced-fields {
	height: 0.2rem;
	line-height: 0.2rem;
	margin-top: -0.05rem;
	margin-bottom: -0.2rem;
	font-size: $small;
	line-height: $small-line-height;
}

@media (hover: hover) {
	// Toggle hint when hovering the option bar.
	#upload-item .option-bar:hover .wrap.toggle:deep() .form-elm:not(.on) {
		background: $black-30;
	}
}

//
//
//
//

@media only screen and (max-width: 950px) {
	.structured-form:deep() .wrap {
		width: 100%;
	}
}

// Option bar
@media only screen and (max-width: 550px), screen and (min-width: $form_) and (max-width: 800px) {
	#upload-item .option-bar {
		flex-direction: column;
	}
	#upload-item .option-bar > .wrap {
		flex: 0 0 0.4rem;
	}
	#upload-item .option-bar > .wrap.toggle {
		flex: 0 0 0.4rem;
		margin: 0;
		padding: 0.1rem;
		padding-bottom: 0.15rem;
	}
}
@media only screen and (max-width: $form) {
	#upload-item {
		flex-direction: column;
		padding: 0.2rem 0;
	}

	// Columns
	#upload-item .form-column {
		flex-basis: 0;
	}
	#upload-item .img-column {
		margin-right: 0;
		margin-bottom: 0.2rem;
		flex-direction: column-reverse;
		flex-basis: 0;
	}

	// File name / remove / loader
	#filename {
		margin-top: 0;
		margin-bottom: 0.1rem;
	}
	#filename span::after {
		content: ' / ';
	}
	#filename a {
		margin-top: 0;
		display: inline-block;
	}
	#progress-bar {
		top: -0.07rem;
	}
	#processing-flag div {
		top: 0.05rem;
	}

	// Advanced fields
	#advanced-fields {
		margin-bottom: 0;
	}

	// Drop zone
	#drop-zone {
		margin-bottom: 0.2rem;
	}
}
</style>
