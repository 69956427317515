export const debugFiles = [
	{
		src: 'https://arthur.io/img/small/000173452a4670b22.jpg',
		name: 'meatballs.jpg',
	},
	{
		src: 'https://arthur.io/img/small/000173452a44cbad7.jpg',
		name: 'tubular.jpg',
	},
	{
		src: 'https://arthur.io/img/small/000173452a4634a44.jpg',
		name: 'tubulation.jpg',
	},
]
export const debugSuccessData = {
	// Success artworks
	successArtworks: [
		{
			title:
				'Meaty Balls But With a Insaaaaaaaaaaanely Long Title That Just Keeps on Going and Going and Going and Going and Going and Going and Going All the Way Until It Stretches Over Multiple Lines To The Point That We Have To Cut It Off',
			id: 'Za1gboaBhDn9',
			artistId: 'abc',
			artistNamePath: 'lara-nasser',
			artistName: 'Lara Nasser',
			category: 'art',
			titlePath: 'worm',
			year: [2016, 2017],
			views: [{ src: 'https://arthur.io/img/small/000173452a4670b22.jpg' }],
		},
		{
			title: 'Worm Stuff',
			id: 'Za1gboaBhDn9',
			artistId: 'abc',
			artistNamePath: 'lara-nasser',
			artistName: 'Lara Abdullah Mohammed Walid Nasser Arnaout Hosain The First',
			category: 'art',
			titlePath: 'worm',
			year: [2016, null, true],
			views: [{ src: 'https://arthur.io/img/small/000173452a44cbad7.jpg' }],
		},
		{
			id: 'Za1gboaBhDn9',
			caption:
				"The flower with the world's largest bloom is the Rafflesia arnoldii. This rare flower is found in the rainforests of Indonesia. It can grow to be 3 feet across and weigh up to 15 pounds! It is a parasitic plant, with no visible leaves, roots, or stem. It attaches itself to a host plant to obtain water and nutrients.",
			views: [
				{
					src:
						'https://img.etimg.com/thumb/msid-73095072,width-640,height-480,imgsize-360997,resizemode-4/worlds-largest-flower.jpg',
				},
			],
		},
	],

	// Failed artworks
	failedArtworks: [
		{
			error: 'Something went wrong while uploading this image. Please try uploading it again.',
			title:
				'Meaty Balls But With a Insaaaaaaaaaaanely Long Title That Just Keeps on Going and Going and Going and Going and Going and Going and Going All the Way Until It Stretches Over Multiple Lines To The Point That We Have To Cut It Off',
			id: 'Za1gboaBhDn9',
			artistNamePath: 'lara-nasser',
			artistName: 'Lara Nasser',
			category: 'art',
			titlePath: 'tube-dude',
			year: [2017],
			views: [{ src: 'invalid.jpg' }],
			index: 0,
		},
		{
			error: 'Something went wrong while dispatching this image. Please try uploading it again.',
			id: 'Za1gboaBhDn9',
			caption:
				"The flower with the world's largest bloom is the Rafflesia arnoldii. This rare flower is found in the rainforests of Indonesia. It can grow to be 3 feet across and weigh up to 15 pounds! It is a parasitic plant, with no visible leaves, roots, or stem. It attaches itself to a host plant to obtain water and nutrients.",
			views: [{ src: 'invalid.jpg' }],
			index: 1,
		},
	],

	// Array with local srcs for failed artworks
	localSrcs: [
		'https://arthur.io/img/small/000173452a4634a44.jpg',
		'https://static01.nyt.com/images/2013/01/20/nyregion/20GRAND1/20GRAND1-articleLarge.jpg',
	],
}
