<template>
	<div class="content-pad">
		<h1>{{ title }}</h1>

		<DebugDisplay
			v-if="sharedPropToggle && debug && (debug == 1 || debug == 3)"
			:data="sharedPropData"
			style="margin-bottom:0.2rem"
		/>

		<!-- Processing state -->
		<template v-if="processing || dummy == 2">
			<div class="processing-anim">
				<img src="/imgs/fast-food-loop.gif" />
			</div>
			<p id="processing-msg">
				{{ processingMessage }}
				<span class="small soft">
					<br /><br />Animation by
					<a target="_blank" href="https://twitter.com/tonybabel">Tony Babel</a>
				</span>
			</p>

			<FormButton :level="2" value="Exit" @click="exitProcessing" />
		</template>

		<!-- Input state -->
		<template v-else>
			<!-- Upload Options -->
			<div id="options" :class="{ processing }">
				<FormToggle label="Art / Design" v-model="isArtworkDefault" @update="errorCount = 0" />
				<FormToggle
					v-if="fileCount > 1 && uploadDataHasArtworks"
					label="Single artist"
					@click="toggleSharedProps"
				/>
				<FormToggle
					v-if="uploadDataHasArtworks"
					label="Advanced fields"
					v-model="showAdvancedFieldsDefault"
				/>
			</div>

			<!-- Shared Props -->
			<div id="single-artist-fields" v-if="sharedPropToggle">
				<FormArtist @update="onUpdateSharedArtist" :debug="debug && debug > 1" />
				<FormDropdown
					label="I am the artist *"
					:options="ddArtistIsSelf"
					:error="sharedPropData.errors.artistIsSelf"
					@update="onUpdateArtistIsSelf"
				/>
			</div>

			<!-- Upload Form -->
			<div v-if="!fileCount" id="loading">Loading...</div>
			<div v-else id="form" class="structured-form">
				<!-- Upload items -->
				<div id="uploads" :class="{ processing }">
					<UploadItem
						v-for="(item, i) in uploadData"
						:key="i"
						:index="i"
						@change="errorCount = 0"
						:debug="debug"
					/>
				</div>

				<!-- Extra drop zone -->
				<label
					id="drop-zone"
					:class="{ hover }"
					@drop.prevent="addFiles"
					@dragenter.prevent="hover = true"
					@dragover.prevent
					@dragleave.prevent="hover = false"
				>
					Drop or select more images
					<input type="file" @change="addFiles" multiple accept="image/*" />
				</label>

				<!-- Error message -->
				<div v-if="errorMsg" id="error-msg">{{ errorMsg }}</div>
				<div v-if="!readyForSubmit" id="wait-msg">
					You can submit once all images are uploaded.
				</div>

				<!-- Submit -->
				<div>
					<FormButton
						ref="btnSubmit"
						:level="1"
						:value="['Submit', 'Submitting']"
						@click="onSubmit"
						:disabled="!readyForSubmit"
					/><FormButton :level="2" value="Cancel" @click="resetForm(true)" />
				</div>
			</div>
		</template>
	</div>
</template>

<script>
// Vue
import { nextTick } from 'vue'
import { mapState } from 'pinia'

// Stores
import { useUploadStore } from '@/stores/UploadStore'
import { useApiStore } from '@/stores/ApiStore'

// Components
import UploadItem from '@/views/Upload/UploadItem'
import FormDropdown from '@/components/FormDropdown'
import FormToggle from '@/components/FormToggle'
import FormButton from '@/components/FormButton'
import FormArtist from '@/components/FormArtist'
import DebugDisplay from '@/components/DebugDisplay'
// import FormText from '@/components/FormText'

// Internal
import { apiUrl } from '@/use/BaseUrls'
import { isSSR } from '@/use/Base'
import { cookieMaxAge, preventLeaveStart, preventLeaveEnd } from '@/use/Helpers'

// External
import Cookies from 'universal-cookie'

export default {
	name: 'UploadForm',
	components: {
		UploadItem,
		FormDropdown,
		FormToggle,
		FormButton,
		FormArtist,
		DebugDisplay,
		// FormText,
	},
	props: {
		// Files passed by drag-and-fdrop or file select.
		files: Object,
		// Temporary filenames sent by browser extension.
		urlFileNames: Object,
		// Object with origin data which is stored in the database and as meta into on B2.
		origins: Object,
		// 1 = Total data
		// 2 = Form item data
		// 3 = Both
		debug: Number,
		// 1 = Dummy upload screen
		// 2 = Dummy success page
		dummy: Number,
	},
	emits: ['reset', 'success'],
	setup() {
		const uploadStore = useUploadStore()
		const apiStore = useApiStore()
		const uploadApi = apiStore.loadApi('upload')
		return { uploadStore, uploadApi }
	},
	data() {
		return {
			// Number of errors on submit. Non-zero will block the form and display a
			// warning at the bottom.
			errorCount: 0,

			// Waiting state while processing
			processing: false,

			// Wait state for buttons (not used atm)
			// waiting: false,

			// Hover state for the file drop zone at the bottom
			hover: false,

			// Single artist options
			artistName: null,
			imTheArtist: false,

			// Dropdown options
			ddArtistIsSelf: [
				{
					display: '- select -',
					selected: true,
				},
				{
					value: true,
					display: 'Yes',
				},
				{
					value: false,
					display: 'No',
				},
			],
		}
	},
	computed: {
		// Pinia state
		...mapState(useUploadStore, [
			'defaults',
			'sharedPropToggle',
			'sharedPropData',
			'uploadData',
			'fileCount',
			// Actions
			'toggleSharedProps',
			'setDefault',
			'updateItem',
			'updateItemError',
			'setSharedProp',
			'setSharedPropError',
			'addMultipleItems',
			'updateMultipleItems',
			'validate',
			'clearStore',
			// Getters
			'formattedData',
			'tempFileNames',
			'readyForSubmit',
		]),

		// v-model isArtworkDefault
		isArtworkDefault: {
			get() {
				return this.defaults.isArtwork
			},
			set(val) {
				this.setDefault('isArtwork', val)
				this.cookies.set('isArtwork', val, {
					path: '/upload',
					maxAge: cookieMaxAge,
				})
			},
		},

		// v-model showAdvancedFieldsDefault
		showAdvancedFieldsDefault: {
			get() {
				return this.defaults.showAdvancedFields
			},
			set(val) {
				this.setDefault('showAdvancedFields', val)
				this.cookies.set('showAdvancedFields', val, {
					path: '/upload',
					maxAge: cookieMaxAge,
				})
			},
		},

		imgCount() {
			return this.urlFileNames.length ? this.urlFileNames.length : this.fileCount
		},
		title() {
			const s = this.imgCount > 1 ? 's' : ''
			return `Uploading ${this.imgCount} Image${s}`
		},
		uploadDataHasArtworks() {
			return this.uploadData.find(itm => itm.isArtwork)
		},
		processingMessage() {
			const s = this.imgCount > 1 ? 's' : ''
			const pronoun = this.imgCount > 1 ? 'They' : 'It'
			return `We're processing your image${s}. ${pronoun} will appear here once we're done, but feel free to go on with your life.`
		},
		// Keep in sync with ArtworkEdit.vue
		errorMsg() {
			if (!this.errorCount) {
				return null
			} else if (this.errorCount == 1) {
				return 'There is one error, scroll up to see details.'
			} else {
				return `There are ${this.errorCount} errors, scroll up to see details.`
			}
		},
	},

	watch: {
		// Reset form when all images are removed.
		fileCount(newValue) {
			if (newValue === 0) this.$emit('reset')
		},
	},

	// Step 2: Process selected files
	async mounted() {
		if (this.urlFileNames.length) {
			// We don't want to process the images on SSR side
			// because we can't load the image (new Image() is browser-only)
			this.processUrlFileNames(this.urlFileNames, this.origins)
		} else if (this.files.length) {
			// Files were uploaded using select or drag-and-drop.
			// - - -
			// Select element provides array-like fileList
			// so we have transform this into a real array.
			await this.processNewFiles(Array.from(this.files))
		}

		// Store cookies object.
		this.cookies = new Cookies()

		// Warn before leaving.
		preventLeaveStart()
	},

	beforeUnmount() {
		// console.log('preventLeaveEnd')
		preventLeaveEnd()
	},

	methods: {
		// Add data to Vuex store.
		updateStoreItem(i, data) {
			this.updateItem(i, data)
		},

		// Update shared artist value.
		onUpdateSharedArtist(artist) {
			const { id, name, error } = artist
			this.setSharedProp('artistId', id || undefined)
			this.setSharedProp('artistName', name || undefined)
			this.setSharedPropError('artist', error || undefined)
		},

		// Update artist is-self.
		onUpdateArtistIsSelf(val) {
			this.setSharedPropError('artistIsSelf', undefined)
			this.setSharedProp('artistIsSelf', val)
		},

		// Execute whenever files are imported via extension.
		// The images were already uploaded by the extension so we can skip
		// all the processing steps.
		async processUrlFileNames(urlFileNames, origins) {
			// urlFileNames are temp filenames that look like:
			// tmp-1643401691412.ESas3L0JRvDO.originalFileName.jpg
			// So we have to transform them into URLS.
			const itemArray = urlFileNames.map((tempFileName, i) => {
				let originalFileName = tempFileName.match(/^tmp-\w+\.\w+\.(.+$)/)
				originalFileName = originalFileName ? originalFileName[1] : tempFileName
				return {
					originalFileName,
					tempFileName,
					src: `${apiUrl}/upload/temp-storage/${tempFileName}`,
					progress: 102,
					imgLoading: true,
					origin: origins[i],
				}
			})
			this.addMultipleItems(itemArray)

			// We can't render image server side.
			if (isSSR) return

			// load image & width & height.
			itemArray.forEach((item, i) => {
				const { src } = item
				const img = new Image()
				img.onload = () => {
					this.updateStoreItem(i, {
						imgLoading: false,
						width: img.width,
						height: img.height,
					})
				}
				img.onerror = () => _ongImgLoadError(img, src, 1000)
				img.src = src
			})

			//
			//

			function _ongImgLoadError(img, src, delay) {
				setTimeout(() => {
					_retryImgLoad(img, src, delay)
				}, delay)
			}

			// When an image load fails it's most likely because its' still being uploaded.
			// So we keep on trying until it loads, but each time the intervals get 1.5 x longer.
			function _retryImgLoad(img, src, delay) {
				img.onerror = () => _ongImgLoadError(img, src, delay * 1.5)
				img.src = ''
				img.src = src
			}
		},

		// Executed whenever (more) files are selected via select or drag-and-drop.
		async processNewFiles(files, baseIndex) {
			// Seed the store with one data object per file: { originalFileName }
			const itemArray = []
			files.forEach(file =>
				itemArray.push({
					originalFileName: file.name,
				})
			)
			this.addMultipleItems(itemArray)

			// Load image src from url or local source.
			this.attachImgSrc(files, baseIndex)

			if (!this.dummy) {
				// Upload image for pre-processing.
				this.uploadImages(files, baseIndex)
			} else {
				// Mark dummy data as uploaded.
				const dummyItemArray = itemArray.map(item => {
					item.progress = 102
					return item
				})
				this.addMultipleItems(dummyItemArray)
			}
		},

		// Attach local disk image src (blob) for immediate display.
		attachImgSrc(files, baseIndex) {
			// baseIndex adds previously uploaded artwork count to the index,
			// so the image loading UI is displayed at the correct artwork.
			baseIndex = baseIndex || 0

			files.forEach((file, i) => {
				// Set src
				let src
				if (this.dummy) {
					src = file.src
					this.updateStoreItem(baseIndex + i, { src })
				} else {
					// Files sent via bookmarklet have their own URL.
					// Local files that are uploaded have a local URL created.
					src = URL.createObjectURL(file)
					this.updateStoreItem(baseIndex + i, { src })
				}

				// Store image dimensions.
				const img = new Image()
				img.onload = () => {
					this.updateStoreItem(baseIndex + i, {
						width: img.width,
						height: img.height,
					})
				}
				img.src = src
			})
		},

		// Upload images in the background while user fills out form.
		async uploadImages(files, baseIndex) {
			// Get temporary file names that we'll use for our temp storage.
			let localFileNames = files.map(file => (file ? file.name : null))
			localFileNames = localFileNames.map(fn =>
				fn
					.replace(/\s+/g, '-')
					.replace(/[^a-z0-9-_.]/gi, '')
					.toLowerCase()
			)
			let { data: tempFileNames } = await this.uploadApi.getTempFileNames(localFileNames)
			// baseIndex adds previously uploaded artwork count to the index,
			// so the image loading UI is displayed at the correct artwork.
			baseIndex = baseIndex || 0

			// Store temp filenames.
			this.updateMultipleItems({
				startIndex: baseIndex,
				dataArray: tempFileNames.map(fn => ({ tempFileName: fn })),
			})

			// Upload every image as a separate request
			// so we can track individual progress.
			const promises = files.map((file, i) => {
				if (!file) return // If image is uploaded from Arthur, file is deleted
				const tempName = tempFileNames[i]
				const promise = this.uploadApi.uploadImage(file, tempName, progress => {
					this.updateStoreItem(baseIndex + i, { progress })
				})
				// Attach individual callback to every image
				this.onImageUploadComplete(promise, baseIndex + i, tempName)
				return promise
			})

			await Promise.all(promises)
			// Global complete action here...
		},

		// Individual success handler per image
		async onImageUploadComplete(promise, i, tempName) {
			// await promise
			const { status, data } = await promise
			const { fileNames, matches } = data
			console.log('Matches: ', matches) // To do: hook up matches

			// Update file status
			if (status == 200) {
				// Display file
			} else {
				// Display error
				this.updateItemError(i, { image: data })
			}

			// In case the file extension was corrected, we need to update the reference path.
			if (fileNames[0] != tempName) {
				this.updateStoreItem(i, { tempFileName: fileNames[0] })
			}

			// Progress 101 --> Hide 'processing...' text
			this.updateStoreItem(i, { progress: 101 })
			await nextTick()

			// Progress 102 --> Transition out the loader
			this.updateStoreItem(i, { progress: 102 })
		},

		// Load extra files from select input or drop event.
		addFiles(e) {
			const files = Array.from(e.target.files || e.dataTransfer.files)
			this.processNewFiles(files, this.fileCount)
			this.hover = false
			e.preventDefault()
		},

		// Submit form --> upload artwork data
		async onSubmit() {
			// Validate form & abort if needed
			this.errorCount = await this.validate()
			if (this.errorCount) return

			if (this.debug) console.log('API Formatted Data:', this.formattedData)

			// Show processing screen
			this.processing = true
			// this.waiting = true

			// Upload form
			const { status, data } = await this.uploadApi.uploadData(this.formattedData)
			if ((status == 200) & this.processing) this.showSuccess(data)

			// // Hide waiting
			// // this.waiting = false
			// // this.processing = false
		},

		// Reset form & show success message
		showSuccess(data) {
			const { successArtworks, failedArtworks } = data
			const localSrcs = Array.from(this.uploadData).map(item => item.src)
			this.resetForm()
			this.$emit('success', { successArtworks, failedArtworks, localSrcs })
		},

		// Cancel form --> reset
		resetForm(abort) {
			// Tell server to delete temp files
			if (abort) {
				this.uploadApi.deteleTempFiles(this.tempFileNames)
			}

			this.clearStore()
			this.data = {}
			this.artistName = null
			this.imTheArtist = false
			this.$emit('reset')
		},

		// Exit processing screen
		exitProcessing() {
			this.processing = false
			this.resetForm()
		},
	},
}
</script>

<style scoped lang="scss">
#page-content {
	max-width: 9.5rem;
}

// Drop zone
#drop-zone {
	display: block;
	text-align: center;
	background: $black-03;
	border: dashed 0.01rem $black-15;
	border-radius: $br;
	width: 100%;
	height: 1.5rem;
	line-height: 1.5rem;
	margin-bottom: 0.4rem;
	align-items: center;
	cursor: pointer;
}
#drop-zone.hover {
	background: $black;
	color: #fff;
}
#drop-zone:hover {
	background: $black-05;
}
#drop-zone input {
	width: 0;
	height: 0;
	display: none;
}

// Options
#options {
	padding-bottom: 0.3rem;
	display: flex;
	flex-direction: row;
	gap: 0.4rem;
	flex-wrap: wrap;
}

// Single artist fields
#single-artist-fields {
	display: flex;
	gap: 0.4rem;
	padding: 0.25rem 0 0.3rem 0;
	border-top: solid 0.01rem $black-10;
}

// Loading
#loading {
	margin-top: 0.3rem;
}

// Itm list
#form {
	margin-bottom: 1rem;
	flex-direction: column;
}
#uploads {
	// Required to truncate locked artist field.
	max-width: 100%;
}
#uploads:deep() > div:first-child {
	border-top: solid 0.01rem $black-10;
}

// Messages above submit
#error-msg,
#wait-msg {
	height: 0.2rem;
	line-height: 0.2rem;
	margin-top: -0.2rem;
	margin-bottom: 0.2rem;
}

// Message saying you can submit once images are uploaded
#wait-msg {
	color: $black-30;
}

// Error message
#error-msg {
	color: $bad;
	animation: blink 170ms 3;
}

/**
 * Processing screen
 */

.processing-anim {
	margin-bottom: 0.3rem;
	position: relative;
}
.processing-anim::after {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	height: 0.2rem;
	background: linear-gradient($bg, transparent);
}
.processing-anim img {
	mix-blend-mode: multiply;
	height: 1.5rem;
}

// Processing message
#processing-msg {
	max-width: 3.8rem;
	margin-bottom: 0.4rem;
}

// Message while wait for submit to go through
// #processing {
// 	font-size: $small;
// 	color: $black-30;
// 	height:0.2rem;
// 	line-height:0.2rem;
// 	margin-top: -0.3rem;
// 	margin-bottom:0.1rem;
// }

// From in grey processing state — might bring this back if we implement
// faster server response with image processing in the back.
// Processing state
// #options.processing,
// #uploads.processing {
// 	pointer-events: none;
// 	opacity: 0.5;
// 	filter: grayscale(100%);
// }

//
//
//
//

@media only screen and (max-width: $form) {
	#options {
		flex-direction: column;
		gap: 0.2rem;
	}

	// Single artist
	#single-artist-fields {
		gap: 0.1rem;
	}
	#single-artist-fields:deep() .wrap {
		width: auto;
	}
	#single-artist-fields > .wrap {
		flex: 1 1;
	}

	#uploads:deep() > div:last-child .wrap.bottom-2 {
		// Nicely fit last itm + dropzone
		margin-bottom: 0.1rem;
	}

	// Drop zone
	#drop-zone {
		margin-bottom: 0.2rem;
	}
}

@media only screen and (max-width: $mobile) {
	#single-artist-fields {
		flex-direction: column;
		gap: 0.3rem;
	}
}
</style>
